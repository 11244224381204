import React from "react";

import { Provider } from "react-redux";
import store from "../coms/data/store";

import MainFrame from "../coms/global/MainFrame";
import Layout from "../coms/global/layout";
import NotFound from "../coms/global/NotFound";

const NotFoundPage = () => {
  return (
    <Provider store={store}>
      <MainFrame>
        <Layout>
          <NotFound />
        </Layout>
      </MainFrame>
    </Provider>
  );
};

export default NotFoundPage;
