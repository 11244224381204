import React from "react";

import { Container, Stack, Typography } from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

import { useSelector } from "react-redux";

const NotFound = () => {
  const texts = useSelector((s) => s.settings.texts.site);

  return (
    <Container maxWidth="md" sx={{ padding: "50px 10px" }}>
      <Stack alignItems={"center"} spacing={3}>
        <SentimentVeryDissatisfiedIcon sx={{ fontSize: "82px" }} />
        <Typography variant="h5" component="h5">
          404
        </Typography>
        <Typography variant="h5" component="h5">
          {texts.notfound}
        </Typography>
      </Stack>
    </Container>
  );
};

export default NotFound;
